<template>
  <div>
    <FleetHome v-if="isFleetManager"></FleetHome>
    <Homepage v-else></Homepage>
  </div>
</template>
<script>
import Homepage from "./homepage.vue";
import FleetHome from "./fleetHome.vue";
export default {
  data() {
    return {
      isFleetManager: JSON.parse(
        decodeURIComponent(window.sessionStorage.getItem("user"))
      ).fleetManager,
    };
  },
  created() {
    console.log("fleetManager:", this.isFleetManager);
  },
  computed: {},
  components: {
    Homepage,
    FleetHome,
  },
};
</script>
