<template>
  <div class="card" @click="goRouter">
    <el-image class="background" :src="background"></el-image>
    <el-image class="img" :src="url" v-show="showIcon"></el-image>
    <div class="text">
      <div class="title" :style="{ color: color }">
        <VueCountUp
          :start-value="0"
          :end-value="title"
          :duration="5"
          :decimals="decimal"
          :options="options"
        />
      </div>
      <div class="sub">{{ sub }}</div>
    </div>
  </div>
</template>
<script>
import VueCountUp from "vue-countupjs";
export default {
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "",
    },
    decimal: {
      type: Number,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    sub: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: {
        useEasing: true, // 缓动动画 easing
        useGrouping: false, // 1,000,000 vs 1000000
        separator: ",", // 数字分隔符
        decimal: ".", // 小数分隔符
        prefix: "", // 前缀
        suffix: "", // 后缀
      },
    };
  },
  components: {
    VueCountUp,
  },
  methods: {
    goRouter() {
      console.log(this.path);
      this.$router.push({
        path: this.path,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
}
.card:active {
  transform: scale(0.9);
}
@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-800px) rotateY(-90deg);
  }
  50% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) rotateY(0);
  }
}
.img {
  width: 68px;
  height: 68px;
  margin-right: 16px;
  transition: transform 0.3s ease;
  animation: rotate 1s ease-out;
}

.img:hover {
  transform: scale(1.3);
  transition: transform 0.3s ease;
}
.title {
  position: relative;
  font-weight: 500;
  font-size: 25px;
  color: #333333;
  z-index: 1;
}
.sub {
  position: relative;
  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  z-index: 1;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
