<template>
  <div class="homepage">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="车队" prop="fleetId">
          <el-select v-model="fleetId" filterable>
            <el-option
              :key="item.id"
              v-for="item in fleetOptions"
              :value="item.id"
              :label="item.name"
              @change="handleSearch"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="date"
            type="daterange"
            @change="handleSearch"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="radio" @change="setDate">
            <el-radio-button label="近一周"></el-radio-button>
            <el-radio-button label="近一月"></el-radio-button>
            <el-radio-button label="近一年"></el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="card-container">
      <Card
        v-for="item in cardList"
        :key="item.sub"
        :title="item.title"
        :sub="item.sub"
        :url="item.url"
        :path="item.path"
        :color="item.color"
        :background="item.background"
        :decimal="item.decimal"
        :showIcon="showIcon"
      >
      </Card>
    </div>
    <div style="display: flex; height: 580px">
      <div id="owner-container">
        <div class="chart-title">货主排名</div>
        <div class="radio-container">
          <el-radio-group v-model="ownerRadio" @change="getOwnerChart">
            <el-radio-button label="发布货源量"></el-radio-button>
            <el-radio-button label="交易金额"></el-radio-button>
          </el-radio-group>
        </div>
        <div id="owner"></div>
      </div>
      <div id="fleet-container">
        <div class="chart-title">车辆运输排名</div>
        <div class="radio-container">
          <el-radio-group v-model="fleetRadio" @change="getFleetChart">
            <el-radio-button label="运输量"></el-radio-button>
            <el-radio-button label="交易量"></el-radio-button>
          </el-radio-group>
        </div>
        <div id="fleet"></div>
      </div>
    </div>
    <div id="trend-container" style="height: 400px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
        "
      >
        <div class="chart-title">数据趋势统计</div>
        <div class="radio-container" style="padding: 20px">
          <el-radio-group v-model="trendRadio" @change="getTrendChart">
            <el-radio-button label="运输量"></el-radio-button>
            <el-radio-button label="派车数"></el-radio-button>
            <el-radio-button label="交易量"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div id="trend"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
import Card from "./components/card.vue";
export default {
  data() {
    return {
      date: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
      showIcon: true,
      fleetId: "",
      fleetOptions: [],
      fleetList: [],

      cardList: [
        {
          title: "0",
          sub: "派车数(次)",
          color: "#FF8F19",
          url: require("../../assets/imgs/home/icon_cds.png"),
          background: require("../../assets/imgs/home/pic_cdsd.png"),
          path: "/transportBill/transportBillList?isFleet=true",
        },
        {
          title: "0",
          sub: "运单数(单）",
          color: "#7465DA",
          url: require("../../assets/imgs/home/icon_tds.png"),
          background: require("../../assets/imgs/home/pic_yds.png"),
          path: "/transportBill/transportBillList?isFleet=true",
        },
        {
          title: "0",
          sub: "交易金额(元)",
          color: "#FF8F19",
          url: require("../../assets/imgs/home/icon_jyje.png"),
          background: require("../../assets/imgs/home/pic_jyje.png"),
          path: "/transportBill/transportBillList",
        },
        {
          title: "0",
          sub: "运输量（吨）",
          color: "#03917F",
          url: require("../../assets/imgs/home/icon_ysl.png"),
          path: "/transportBill/transportBillList",
          background: require("../../assets/imgs/home/pic_ysl.png"),
        },
        /*{
            title: "123,456",
            sub: "充电桩(根)",
            url: require("../../assets/imgs/home/icon_cdz.png"),
          },*/
      ],
      radio: "近一周",
      ownerRadio: "发布货源量",
      fleetRadio: "运输量",
      trendRadio: "运输量",
      ownerOption: {
        tooltip: {
          show: true,
        },

        grid: {
          left: "10px",
          right: "100px",
          top: "10px",
          bottom: "10px",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisLabel: {
            fontSize: 14,
            fontFamily: "Source Han Sans CN",
          },
          axisLine: {
            show: false,
            nameTextStyle: {
              fontSize: 16,
            },
          },
          axisTick: {
            show: false,
          },
          type: "category",
        },
        series: [
          {
            data: [],
            barWidth: "30",
            type: "bar",
            label: {
              fontFamily: "Source Han Sans CN",
              show: true,
              position: "right",
              formatter: (data) => {
                return (
                  data.value + (this.ownerRadio == "发布货源量" ? "吨" : "元")
                );
              },
            },
          },
        ],
      },
      fleetOption: {
        tooltip: {
          show: true,
        },
        grid: {
          left: "10px",
          right: "100px",
          top: "10px",
          bottom: "10px",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisLabel: {
            fontSize: 14,
            fontFamily: "Source Han Sans CN",
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          type: "category",
          data: [],
        },
        series: [
          {
            showBackground: true,
            backgroundStyle: {
              color: "#F0F3F5",
              borderRadius: 10,
            },
            data: [],
            type: "bar",
            barWidth: "15",
            itemStyle: {
              borderRadius: 10, // 统一设置四个角的圆角大小
              //borderRadius: [10, 10, 0, 0] //（顺时针左上，右上，右下，左下）
            },
            label: {
              fontFamily: "Source Han Sans CN",
              show: true,
              position: "right",
              formatter: (data) => {
                return data.value + (this.fleetRadio == "运输量" ? "吨" : "元");
              },
            },
          },
        ],
      },
      trendOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: (params) => {
            console.log(params[0].data);
            // 自定义X轴坐标点对应的label
            return (
              params[0].axisValue +
              "</br><div style='width: 9px; height:9px; border: 1px solid #1890ff; border-radius:6px; background-color: #1890ff;float: left; margin: 4px'></div>" +
              "<div style='float: right;font-weight:bold'> " +
              params[0].data +
              (this.trendRadio == "运输量"
                ? "吨"
                : this.trendRadio == "派车数"
                ? "次"
                : "元") +
              "</div>"
            );
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            showSymbol: false,
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#4FD1C5",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 1,
                  color: "#2CBDDF",
                },
                {
                  offset: 0,
                  color: "#FFFFFF",
                },
              ]),
            },
          },
        ],
      },
    };
  },
  components: {
    Card,
  },
  created() {
    this.getFleetOptions();

    //this.getPageInfo();
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    setDate() {
      if (this.radio == "近一周") {
        this.date = [
          new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          new Date(),
        ];
      }
      if (this.radio == "近一月") {
        this.date = [
          new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
          new Date(),
        ];
      }
      if (this.radio == "近一年") {
        this.date = [
          new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
          new Date(),
        ];
      }
      this.handleSearch();
    },
    handleSearch() {
      this.getCards();
      this.getOwnerChart();
      this.getFleetChart();
    },
    getCondition() {
      let that = this;
      return {
        begin: that.date[0] ? new dayjs(that.date[0]).format("YYYY-MM-DD") : "",
        end: that.date[1] ? new dayjs(that.date[1]).format("YYYY-MM-DD") : "",
        fleetId: that.fleetId,
      };
    },
    async getFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/ownList",
        "get",
        {}
      );
      this.fleetOptions = data.data;
      if (this.fleetOptions.length > 0) {
        this.fleetId = this.fleetOptions[0].id;
        this.handleSearch();
        this.getTrendChart();
      }
    },
    initCharts() {
      var ownerDom = document.getElementById("owner");
      var ownerChart = echarts.init(ownerDom);
      this.ownerOption && ownerChart.setOption(this.ownerOption);
      var fleetDom = document.getElementById("fleet");
      var fleetChart = echarts.init(fleetDom);
      this.fleetOption && fleetChart.setOption(this.fleetOption);
      var trendDom = document.getElementById("trend");
      var trendChart = echarts.init(trendDom);
      this.trendOption && trendChart.setOption(this.trendOption);
    },
    async getCards() {
      this.showIcon = false;
      console.log(this.getCondition());
      const { data } = await this.$http(
        "api1",
        "/api/stat/statNums",
        "get",
        this.getCondition()
      );
      this.showIcon = true;
      console.log(data);
      this.cardList[0].title = data.data.dispatch;
      this.cardList[1].title = data.data.transport;
      this.cardList[2].title = data.data.amount;
      this.cardList[3].title = data.data.ton;
    },
    async getOwnerChart() {
      let res = "";
      if (this.ownerRadio == "发布货源量") {
        res = await this.$http(
          "api1",
          "/api/stat/cargoOwnerRankingByTons",
          "get",
          this.getCondition()
        );
      } else {
        res = await this.$http(
          "api1",
          "/api/stat/cargoOwnerRankingByFreight",
          "get",
          this.getCondition()
        );
      }
      console.log(res);
      this.ownerOption.yAxis.data = res.data.data.map((item) => item.name);
      let result = [];
      let colors = [
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#8375ED" },
          { offset: 1, color: "#A3B4FC" },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#56AAFA" },
          { offset: 1, color: "#83F7D7" },
        ]),
      ];
      let hoverColors = [
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#8375ED" },
          { offset: 0.2, color: "#A3B4FC" },
          { offset: 1, color: "#A3B4FC" },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#56AAFA" },
          { offset: 0.2, color: "#83F7D7" },
          { offset: 1, color: "#83F7D7" },
        ]),
      ];
      res.data.data.forEach((item, index) => {
        result.push({
          value: item.num,
          itemStyle: {
            color: colors[index % 2],
          },
          emphasis: {
            itemStyle: {
              color: hoverColors[index % 2],
            },
          },
        });
      });
      this.ownerOption.series[0].data = result;
      this.initCharts();
    },
    async getFleetChart() {
      let res = "";
      if (this.fleetRadio == "运输量") {
        res = await this.$http(
          "api1",
          "/api/stat/trunkRankingByTons",
          "get",
          this.getCondition()
        );
      } else {
        res = await this.$http(
          "api1",
          "/api/stat/trunkRankingByFreight",
          "get",
          this.getCondition()
        );
      }
      console.log(res);
      this.fleetOption.yAxis.data = res.data.data.map((item) => item.name);
      let result = [];
      let colors = [
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#8375ED" },
          { offset: 1, color: "#A3B4FC" },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#EE6D6B" },
          { offset: 1, color: "#FFDBBF" },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#56AAFA" },
          { offset: 1, color: "#83F7D7" },
        ]),
      ];
      let hoverColors = [
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#8375ED" },
          { offset: 0.2, color: "#A3B4FC" },
          { offset: 1, color: "#A3B4FC" },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#EE6D6B" },
          { offset: 0.2, color: "#FFDBBF" },
          { offset: 1, color: "#FFDBBF" },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: "#56AAFA" },
          { offset: 0.2, color: "#83F7D7" },
          { offset: 1, color: "#83F7D7" },
        ]),
      ];
      res.data.data.forEach((item, index) => {
        result.push({
          value: item.num,
          itemStyle: {
            color: colors[index % 3],
          },
          emphasis: {
            itemStyle: {
              color: hoverColors[index % 3],
            },
          },
        });
      });
      this.fleetOption.series[0].data = result;
      this.initCharts();
    },
    async getTrendChart() {
      let res = "";
      if (this.trendRadio == "运输量") {
        res = await this.$http(
          "api1",
          "/api/stat/trendStatByTons",
          "get",
          this.getCondition()
        );
      } else if (this.trendRadio == "派车数") {
        res = await this.$http(
          "api1",
          "/api/stat/trendStatByTrunk",
          "get",
          this.getCondition()
        );
      } else {
        res = await this.$http(
          "api1",
          "/api/stat/trendStatByFreight",
          "get",
          this.getCondition()
        );
      }
      console.log(res);
      this.trendOption.xAxis.data = res.data.data.map((item) => item.dateStr);
      this.trendOption.series[0].data = res.data.data.map((item) => item.num);
      this.initCharts();
    },
  },
};
</script>
<style lang="scss" scoped>
.main_container_box {
  height: 100%;
}

.homepage {
  height: 100%;
}
.search-box {
  padding-top: 20px;
  padding-left: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.card-container {
  margin: 20px 0;
  height: 120px;
  display: flex;
  * {
    flex: 1;
    margin: 0 5px;
  }
}
.homepage_content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.icon {
  width: 68px;
  height: 68px;
  margin-right: 16px;
}
.chart-title {
  font-size: 20px;
  color: #202647;
  font-style: italic;
  font-weight: bold;
  padding: 20px;
}
#owner-container {
  flex: 1.2;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
#fleet-container {
  background-color: #fff;
  margin-left: 10px;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
#trend-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
#owner {
  flex: 1;
}
#fleet {
  flex: 1;
}
#trend {
  flex: 1;
}
.radio-container {
  padding-left: 20px;
}
</style>
